@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "OutfitRegular";
  src: url("../../public/fonts/Outfit-Regular.ttf");
}
@font-face {
  font-family: "OutfitBold";
  src: url("../../public/fonts/Outfit-Bold.ttf");
}
@font-face {
  font-family: "OutfitLight";
  src: url("../../public/fonts/Outfit-Light.ttf");
}

@layer base {
  html {
    font-family: OutfitRegular, Nunito Sans, system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth !important;
  }

  * {
    font-family: OutfitRegular, Nunito Sans, system-ui, sans-serif;
    scroll-behavior: smooth !important;
  }

  body {
    @apply bg-layout-scene;

    max-width: 1500px;
    margin: 0 auto;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @keyframes asideIn {
    0% {
      @apply w-0;
    }
    100% {
      @apply w-72;
    }
  }

  @keyframes asideOut {
    0% {
      @apply w-72;
    }
    100% {
      @apply w-0;
    }
  }

  @keyframes controlIn {
    0% {
      @apply left-0 opacity-0;
    }
    100% {
      @apply opacity-100 left-72;
    }
  }

  @keyframes controlOut {
    0% {
      @apply opacity-100 left-72;
    }
    100% {
      @apply left-0 opacity-100;
    }
  }

  @keyframes controlFadeOut {
    0% {
      @apply opacity-100 left-72;
    }
    100% {
      @apply left-0 opacity-0;
    }
  }
}

.custom-font-bold {
  font-family: OutfitBold, Nunito Sans, system-ui, sans-serif !important;
}

.custom-download-bg {
  background: linear-gradient(
    180deg,
    rgba(244, 244, 246, 0.58) 0%,
    #f4f4f6 100%
  );
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Toastify reset */
.Toastify__toast {
  min-height: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  padding: unset !important;
  margin: unset !important;
}

.Toastify__toast-body {
  padding: unset !important;
  margin: unset !important;
}

.Toastify__close-button {
  display: none;
}
/* Toastify reset */

.react-datepicker-wrapper {
  width: unset !important;
}

/* Drawer Custom Style */
.filter-drawer {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.custom-mobile-drawer {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.custom-mobile-drawer .ant-drawer-header {
  padding: 24px 16px 0px;
}

.custom-mobile-drawer .ant-drawer-body {
  padding: 16px !important;
}

.email-customer-modal .ant-modal-content {
  border-radius: 0px;
  height: 800px;
  overflow-y: scroll;
}

.ant-popover .ant-popover-inner {
  width: 300px;
}
.ant-popconfirm-buttons .ant-btn-primary {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.upload-document-modal-container .ant-modal-content {
  padding: 0px;
}
.upload-document-modal-container .ant-modal .ant-modal-title {
  color: #fbc73b;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  background: #30345e;
  padding: 14px 24px;
}
.upload-document-modal-container .ant-modal .ant-modal-content {
  border-radius: 0px;
}

.upload-additional-car-image .ant-modal-content {
  padding: 0px;
}
.upload-additional-car-image .ant-modal .ant-modal-title {
  color: #30345e;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #e5e7eb;
  padding: 14px 24px;
}
.upload-additional-car-image .ant-modal .ant-modal-content {
  border-radius: 0px;
}

.ant-image-preview-root .ant-image-preview-wrap {
  background: rgba(18, 18, 18, 0.9);
}

.dfc-gradient {
  background: linear-gradient(326deg, #222650 -0.15%, #4f558d 88.22%);
}

.ant-progress-inner {
  height: 0.1875rem !important;
}

.ant-progress-line {
  margin-bottom: 0px !important;
}

.special-row {
  border: none !important;
}

.special-row td:first-child {
  border-start-start-radius: 0.5rem !important;
  border-end-start-radius: 0.5rem !important;
}
.special-row td:nth-child(3) {
  border-start-end-radius: 0.5rem !important;
  border-end-end-radius: 0.5rem !important;

  border-bottom: none;
}

.ant-modal,
.ant-modal-close:hover {
  background-color: transparent !important;
}
.ant-modal .ant-modal-content {
  /* background-color: transparent !important; */
}

.ant-modal-close {
  display: none !important;
}

.ant-modal-root .ant-modal-wrap {
  z-index: 1007 !important;
}

.benefit-modal .ant-modal .ant-modal-content {
  padding: 0px;
  border-radius: 18px;
}

.dealer-loan-offer .ant-tabs .ant-tabs-tab {
  padding: 8px 16px !important;
  border: none !important;
  background: none;
}
.dealer-loan-offer .ant-tabs .ant-tabs-tab-active {
  padding: 8px 16px !important;
  border: 1px solid #e5e7eb !important;
  /* border: 1px solid rgba(5, 5, 5, 0.06) !important; */
  background: rgba(0, 0, 0, 0.02) !important;
}

@media (max-width: 576px) {
  .email-customer-modal .ant-modal-content {
    padding: 24px 12px 30px;
  }
}

/* Drawer Custom Style */
.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(6px);
  opacity: 1;
}

.popconfirm__inventory_filter .ant-popconfirm-message {
  display: none;
}

.popconfirm__inventory_filter .ant-popconfirm-description {
  margin: 0;
}
.inventory_search_inputField .MuiAutocomplete-inputRoot {
  padding: 3px 30px 3px 10px !important;
  /* background-color: red; */
}

.inventory_search_inputField {
  background-color: #f8fafc;
}

/* .inventory_search_inputField .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 10px !important;
} */

.config_search_inputField .MuiAutocomplete-inputRoot {
  padding: 5px 30px 5px 10px !important;
  background-color: #f8fafc;
}

.config_search_inputField .MuiTextField-root {
  padding: 5px 30px 5px 10px !important;
}

.loan-calculator-modal .ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;

  padding: 8px;
}

#file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ant-btn-primary {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
